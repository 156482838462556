<template>
  <ul class="nav pull-right top-menu mt-3">
    <!-- user login dropdown start-->
    <li class="dropdown">
      <a data-toggle="dropdown" class="dropdown-toggle" href="#">
        <img alt="avatar img" class="image-avatar" src="/img/avatar-bg.png" />
        <span class="username">{{ this.getUser.firstName }}</span>
        <b class="caret"></b>
      </a>
      <ul class="dropdown-menu extended logout">
        <div class="log-arrow-up"></div>

        <li>
          <router-link :to="{ name: 'neworder' }"
            ><i class="fas fa-plus"></i> New order</router-link
          >
        </li>
        <li
          v-if="getUser.role === 'Admin' || this.getUser.isSuperUser === true"
        >
          <router-link :to="{ name: 'allorders' }"
            ><i class="fas fa-grip-lines"></i> All orders</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'notifications' }"
            ><i class="fas fa-bell"></i> Notification</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'profile' }"
            ><i class=" fas fa-suitcase"></i>Profile</router-link
          >
        </li>
        <li v-if="getUser.role === 'Admin'">
          <router-link :to="{ name: 'items' }"
            ><i class="fas fa-users-cog"></i> Admin</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'settings' }"
            ><i class="fas fa-cog"></i> Settings</router-link
          >
        </li>
        <li>
          <a href="/logout"><i class="fas fa-key"></i> Log Out</a>
        </li>
      </ul>
    </li>
    <!-- user login dropdown end -->
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UserLoginDropDown",
  computed: mapGetters(["getUser"])
};
</script>

<style></style>

<template>
  <section id="container" class="full-width">
    <!--header start-->
    <header class="header black-bg d-print-none">
      <div>
        <!--logo start-->
        <a href="/order" class="logo text-white"
          ><img src="/img/CreoNordicLogohvit.png" class="logoimg" /> CreoNordic
        </a>

        <div class="top-nav">
          <User-Login-Drop-Down class="float-right"></User-Login-Drop-Down>
        </div>
      </div>
    </header>
    <!--header end-->
    <!--sidebar start-->

    <section id="main-content" class="">
      <div class="wrapper">
        <router-view />
        <br />
      </div>
    </section>
  </section>
</template>

<script>
import UserLoginDropDown from "@/components/UserLoginDropDown";

export default {
  name: "order",
  components: {
    UserLoginDropDown
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

.image-avatar {
  height: 25px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .logo div {
    display: none;
  }
  .logo:after {
    content: "Order";
  }
}
</style>
